// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Copyright',
  slug: '/copyright',
  abstract: 'Copyright',
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'Copyright', slug: '/copyright' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
// export const query = graphql`
//   query {
//     launch: file(relativePath: { eq: "launch.jpg" }) {
//       ...max900
//     }
//   }
// `

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <StandardPageWrapper className="" pageSchema={pageSchema} {...props}>
    <div className="container">
      <h1>Copyright</h1>
      <p>
        All texts are the copyright of the Sri Aurobindo Ashram Trust
        Pondicherry, with the exception of the texts from Mother’s Agenda
      </p>
      <p>
        This book also includes texts from "Georges Van Vrekhem, Preparing for
        the Miraculous Stichting Aurofonds"
      </p>
      <p>Cover photo: 24-04-1966, The Mother’s Terrace Darshan</p>
      <p>Compiled by Martin Sobieroj and Franz Fassbender</p>
      <p>First edition 2015, Second revised edition 2021</p>
    </div>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
